import { UnwrapNestedRefs } from "@vue/reactivity";
import { FormRules } from "element-plus";
import { reactive } from "vue";
import { CatalogsForm } from "@/components/static/catalogs-form/catalogs-form.types";

export const _catalogsFormRules = (): UnwrapNestedRefs<
  FormRules<CatalogsForm>
> => {
  return reactive<FormRules<CatalogsForm>>({});
};

import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import utils from "@/utils";

export default async function (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const isAuthenticated = utils.getUserTokenFromStorage();

  if (isAuthenticated) {
    next({ name: "Home" });
  } else {
    next();
  }
}

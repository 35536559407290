import { defineStore } from "pinia";
import { ApiResponses, Types } from "patchandgo-ui";
import request from "@/store/request";
import utils from "@/utils";
import { FilterFunction } from "@/utils/types";
import { NewUserForm } from "@/components/new-user-form/new-user-form.types";
import { ShippingModalForm } from "@/components/modals/users/shippingModal.types";
import {
  UserCommonForm,
  UserForm,
  UserPasswordForm,
} from "@/components/user-form/user-form.types";

interface UsersStoreState {
  users: Types.User[];
  total: number;
}

export const useUsersStore = defineStore("usersStore", {
  state(): UsersStoreState {
    return {
      users: [],
      total: 0,
    };
  },
  actions: {
    async getUsers(
      params: Types.GetUsersParams = {},
      filterFunction: FilterFunction<Types.User> = () => true
    ) {
      try {
        const { users, total } = await this.getUsersRaw(params, filterFunction);
        this.users = users;
        this.total = total;
      } catch (err) {
        utils.handleHttpError(err as number);
      }
    },
    async getUsersRaw(
      params: Types.GetUsersParams = {},
      filterFunction: FilterFunction<Types.User> = () => true
    ) {
      try {
        const data = await request.send<ApiResponses.UsersListResponse>(
          "get",
          "/user/",
          params
        );

        return data;
      } catch (err) {
        utils.handleHttpError(err as number);
      }
    },
    createUser(form: NewUserForm) {
      const { login, display_name, email, password, role } = form;
      const params = {
        login,
        display_name,
        email,
        password,
        role,
      };
      return request.send<any>("post", "/user/", params);
    },
    createShippingItem(id: string | number, form: ShippingModalForm) {
      try {
        return request.send<any>("post", `/user/${id}/shipping`, form);
      } catch (err) {
        utils.handleHttpError(err as number);
      }
    },
    getUserById(id: number | string) {
      try {
        return request.send<ApiResponses.ProfileGetResponse>(
          "get",
          `/user/${id}`
        );
      } catch (err) {
        utils.handleHttpError(err as number);
      }
    },
    changeUserAccessStatus(status: boolean, id: string | number) {
      const activate = "/user/activate/";
      const deactivate = "/user/deactivate/";

      try {
        return request.send<ApiResponses.ProfileGetResponse>(
          "put",
          `${status ? activate : deactivate}${id}`
        );
      } catch (err) {
        utils.handleHttpError(err as number);
      }
    },
    changePassword(form: UserPasswordForm, id: number | string) {
      try {
        return request.send<void>("put", `/user/${id}`, {
          password: form.password,
        });
      } catch (err) {
        utils.handleHttpError(err as number);
      }
    },
    changeUserCommonInfoById(form: UserCommonForm, id: string | number) {
      try {
        return request.send<void>("put", `/user/${id}/contact`, form);
      } catch (err) {
        utils.handleHttpError(err as number);
      }
    },
    changeUserInfoById(form: UserForm, id: string | number) {
      try {
        return request.send<void>("put", `/user/${id}`, form);
      } catch (err) {
        utils.handleHttpError(err as number);
      }
    },
    filterUsers() {
      //
    },
  },
});

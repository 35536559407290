import { UnwrapNestedRefs } from "@vue/reactivity";
import { FormRules } from "element-plus";
import { reactive } from "vue";
import { EffectsForm } from "@/components/static/effects-form/effects-form.types";

export const _effectsFormRules = (): UnwrapNestedRefs<
  FormRules<EffectsForm>
> => {
  return reactive<FormRules<EffectsForm>>({});
};

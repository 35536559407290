import { useHeadingStore } from "@/store/heading.store";

export default async function () {
  const headingStore = useHeadingStore();
  headingStore.setHeading({
    heading: null,
    subHeading: null,
    backUrl: null,
  });
}

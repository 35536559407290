import { PageState } from "@/utils/reactivity.types";
import { ref } from "vue";

export function debounce(func: any, timeout = 500) {
  let timer: ReturnType<typeof setInterval> | null = null;
  return (...args: any[]) => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      if (typeof func === "function") func.apply(this, args);
    }, timeout);
  };
}

export function makePageState<T>() {
  return ref<PageState<T>>({
    data: [] as T[],
    search: "",
    loading: true,
    page: 1,
  });
}

import { defineStore } from "pinia";
import { ApiResponses, Types } from "patchandgo-ui";
import utils from "@/utils";
import request from "@/store/request";
import { ProductForm } from "@/components/product-form/product-form.types";
import slug from "slug";

export interface ProductsStoreState {
  products: Types.Product[];
  total: number;
}

export const useProductsStore = defineStore("productsStore", {
  state: (): ProductsStoreState => {
    return {
      products: [],
      total: 0,
    };
  },
  actions: {
    async getProducts(params: Types.GetProductsParams = {}) {
      try {
        const { products, total } =
          await request.send<ApiResponses.GetProductsResponse>(
            "get",
            "/product/",
            params
          );

        this.products = products;
        this.total = total;
        return products;
      } catch (err) {
        utils.handleHttpError(err as number);
      }
    },
    async getProductById(id: number | string) {
      try {
        return await request.send("get", `/product/${id}`);
      } catch (err) {
        utils.handleHttpError(err as number);
      }
    },
    async deleteProductById(id: number | string) {
      try {
        return await request.send("delete", `/product/${id}`);
      } catch (err) {
        utils.handleHttpError(err as number);
      }
    },
    async restoreProductById(id: number | string) {
      try {
        return await request.send("post", `/product/${id}/restore`);
      } catch (err) {
        utils.handleHttpError(err as number);
      }
    },
    async updateProductById(id: number | string, form: ProductForm) {
      try {
        return await request.send("put", `/product/${id}`, form);
      } catch (err) {
        utils.handleHttpError(err as number);
      }
    },
    async saveNew(form: ProductForm) {
      const newForm: ProductForm = {
        ...form,
        slug: slug(form.title),
      };
      try {
        return await request.send<any>("post", `/product/`, newForm);
      } catch (err) {
        utils.handleHttpError(err as number);
      }
    },
  },
});

import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import AllUsersView from "@/views/users/AllUsersView.vue";
import HomeView from "@/views/HomeView.vue";
import UserFormView from "@/views/users/UserFormView.vue";
import AppLayout from "@/layouts/app.layout.vue";
import LoginView from "@/views/auth/LoginView.vue";
import AuthLayout from "@/layouts/auth.layout.vue";
import authMiddleware from "@/router/auth.middleware";
import authPagesMiddleware from "@/router/auth-pages.middleware";
import resetHeadingMiddleware from "@/router/resetHeading.middleware";
import NewUserView from "@/views/users/NewUserView.vue";
import AllOrdersPageRetail from "@/views/orders/AllOrdersPageRetail.vue";
import AllOrdersPageWholesale from "@/views/orders/AllOrdersPageWholesale.vue";
import OrderFormView from "@/views/orders/OrderFormView.vue";
import OrderPageOpenByNumber from "@/views/orders/OpenByNumber.vue";
import StaticBrandsView from "@/views/static/StaticBrandsView.vue";
import StaticCatalogsView from "@/views/static/StaticCatalogsView.vue";
import StaticIngredientsView from "@/views/static/StaticIngredientsView.vue";
import StaticEffectsView from "@/views/static/StaticEffectsView.vue";
import StaticSkinTypesView from "@/views/static/StaticSkinTypesView.vue";
import StaticSkinConditionsView from "@/views/static/StaticSkinConditionView.vue";
import StaticShippingTksView from "@/views/static/StaticShippingTksView.vue";
import AllProductsView from "@/views/products/AllProductsView.vue";
import ProductFormView from "@/views/products/ProductFormView.vue";
import NewProductFormView from "@/views/products/NewProductFormView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: AppLayout,
    beforeEnter: [authMiddleware],
    children: [
      {
        path: "/",
        name: "Home",
        component: HomeView,
      },
      {
        path: "/users",
        name: "AllUsers",
        component: AllUsersView,
      },
      {
        path: "/user/:id",
        name: "UserEditForm",
        component: UserFormView,
      },
      {
        path: "/user-new",
        name: "UserNewForm",
        component: NewUserView,
      },
      {
        path: "/products",
        name: "AllProducts",
        component: AllProductsView,
      },
      {
        path: "/product/:id",
        name: "ProductEditForm",
        component: ProductFormView,
      },
      {
        path: "/product-new",
        name: "NewProductForm",
        component: NewProductFormView,
      },
      {
        path: "/static",
        children: [
          {
            path: "brands",
            name: "StaticBrands",
            component: StaticBrandsView,
          },
          {
            path: "catalogs",
            name: "StaticCatalogs",
            component: StaticCatalogsView,
          },
          {
            path: "ingredients",
            name: "StaticIngredients",
            component: StaticIngredientsView,
          },
          {
            path: "effects",
            name: "StaticEffects",
            component: StaticEffectsView,
          },
          {
            path: "skin-types",
            name: "StaticSkinTypes",
            component: StaticSkinTypesView,
          },
          {
            path: "skin-conditions",
            name: "StaticSkinConditions",
            component: StaticSkinConditionsView,
          },
          {
            path: "shipping",
            name: "StaticShippingTks",
            component: StaticShippingTksView,
          },
        ],
      },
      {
        path: "/orders-retail",
        name: "OrdersPageRetail",
        component: AllOrdersPageRetail,
      },
      {
        path: "/orders-wholesale",
        name: "OrdersPageWholesale",
        component: AllOrdersPageWholesale,
      },
      {
        path: "/orders-open",
        name: "OrderPageOpenByNumber",
        component: OrderPageOpenByNumber,
      },
      {
        path: "/order/:id",
        name: "OrderPage",
        component: OrderFormView,
      },
    ],
  },
  {
    path: "/auth",
    component: AuthLayout,
    redirect: () => {
      return {
        name: "LoginPage",
      };
    },
    beforeEnter: [authPagesMiddleware],
    children: [
      {
        path: "login",
        name: "LoginPage",
        component: LoginView,
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: (to) => {
      return { name: "Home" };
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.afterEach(resetHeadingMiddleware);

export default router;

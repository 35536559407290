import { defineStore } from "pinia";
import { RouteLocationRaw } from "vue-router";

const BASE_TAB_TITLE = "Patch And Go | Admin";

export interface HeadingStoreState {
  heading: string | number | null;
  subHeading: string | number | null;
  backUrl: RouteLocationRaw | null;
}

export interface SetHeadingParams {
  heading: string | number | null;
  subHeading?: string | number | null;
  backUrl?: RouteLocationRaw | null;
}

export const useHeadingStore = defineStore("headingStore", {
  state(): HeadingStoreState {
    return {
      heading: null,
      subHeading: null,
      backUrl: null,
    };
  },
  actions: {
    setHeading(params: SetHeadingParams) {
      const { heading, subHeading, backUrl } = params;
      this.heading = heading;
      this.subHeading = subHeading || null;
      this.backUrl = backUrl || null;
      let tabTitle = BASE_TAB_TITLE;

      if (this.heading) {
        tabTitle += ` | ${this.heading} ${this.subHeading || ""}`;
      }

      document.title = tabTitle;
    },
  },
});

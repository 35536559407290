import { Ref, UnwrapNestedRefs } from "@vue/reactivity";
import { FormInstance, FormRules } from "element-plus";
import { reactive, ref } from "vue";
import { MessageService, PagUtilsCommon } from "patchandgo-ui";

export interface MakeFormRef<T, R> {
  ref: Ref<FormInstance>;
  form: UnwrapNestedRefs<T>;
  rules: UnwrapNestedRefs<FormRules<T>>;
  submit: (formEl: FormInstance | undefined) => Promise<R | undefined>;
  fill: (f: Partial<T>) => void;
  loading: () => boolean;
  clear: () => void;
}
export function makeForm<T extends object, R>(
  _form: T,
  _rules: (f: UnwrapNestedRefs<T>) => UnwrapNestedRefs<FormRules<T>>,
  _submitFunction: (form: UnwrapNestedRefs<T>) => Promise<R>,
  _successfulMessage = "Сохранено успешно"
): MakeFormRef<T, R> {
  const form = reactive<T>(PagUtilsCommon.cloneWithoutBinding(_form));
  const loading = ref(false);
  return {
    form,
    loading: () => loading.value,
    rules: _rules(form),
    ref: ref<FormInstance>(),
    submit: async (
      formEl: FormInstance | undefined
    ): Promise<R | undefined> => {
      if (!formEl) return;
      await formEl.validate(async (valid, fields) => {
        if (valid) {
          if (typeof _submitFunction === "function") {
            loading.value = true;
            try {
              const data = await _submitFunction(form);
              MessageService.showSuccessMessage(_successfulMessage);
              return data;
            } catch (err) {
              console.log(err);
            } finally {
              loading.value = false;
            }
          }
        }
      });
    },
    fill(f) {
      Object.entries(f).forEach(([key, value]) => {
        if (key in form && value) {
          form[key] = value;
        }
      });
    },
    clear() {
      Object.entries(_form).forEach(([key, value]) => {
        if (key in form) {
          form[key] = value;
        }
      });
    },
  } as MakeFormRef<T, R>;
}

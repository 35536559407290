import { defineStore } from "pinia";
import { ApiMethods, ApiResponses } from "../../../framework";
import utils from "@/utils";
import request from "@/store/request";

export interface UserStoreState {
  isLogin: boolean;
  user: object | null;
}

export const useUserStore = defineStore("userStore", {
  state: (): UserStoreState => {
    return {
      isLogin: false,
      user: null,
    };
  },
  actions: {
    async login(login: string, password: string) {
      try {
        const loginData = await request.send<ApiResponses.LoginPostResponse>(
          "post",
          ApiMethods.LOGIN,
          { login, password },
          false
        );

        utils.setAuthDataInStorage(loginData, true);
      } catch (err) {
        utils.handleHttpError(err as number);
      }
    },
    async refreshToken() {
      try {
        const loginData = await request.send<ApiResponses.LoginPostResponse>(
          "post",
          ApiMethods.AUTH_REFRESH,
          {
            access: utils.getUserTokenFromStorage(),
            refresh: utils.getUserRefreshTokenFromStorage(),
          },
          false,
          true
        );
        utils.setAuthDataInStorage(
          loginData,
          utils.stringToBoolean(utils.getUserRememberValueFromStorage() || "0")
        );
      } catch (err) {
        utils.handleHttpError(err as number);
      }
    },
    async logout() {
      try {
        utils.removeAllTokensInStorage();
        this.user = null;
        this.isLogin = false;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        await this.$router.push({ name: "LoginPage" });
      } catch (err) {
        console.log(err);
      }
    },
  },
});

import { UnwrapNestedRefs } from "@vue/reactivity";
import { FormRules } from "element-plus";
import { reactive } from "vue";
import {
  OrderWholesaleDeliveryForm,
  OrderWholesaleMainInfoForm,
} from "@/components/orders-forms/order-wholesale-form/order-wholesale.types";

export const _orderWholesaleMainInfoFormRules = (): UnwrapNestedRefs<
  FormRules<OrderWholesaleMainInfoForm>
> => {
  return reactive<FormRules<OrderWholesaleMainInfoForm>>({});
};

export const _orderWholesaleMainDeliveryFormRules = (): UnwrapNestedRefs<
  FormRules<OrderWholesaleDeliveryForm>
> => {
  return reactive<FormRules<OrderWholesaleDeliveryForm>>({
    address: [{ required: true, trigger: "blur" }],
    insurance: [{ required: true, trigger: "change" }],
    warm: [{ required: true, trigger: "change" }],
    crate: [{ required: true, trigger: "change" }],
    tk: [{ required: true, trigger: "change" }],
    destination: [{ required: true, trigger: "change" }],
  });
};

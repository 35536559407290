import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import utils from "@/utils";
import { useUserStore } from "@/store/user.store";
import { useStaticStore } from "@/store/static.store";

export default async function (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const userStore = useUserStore();
  const staticStore = useStaticStore();
  const isAuthenticated = !!utils.getUserTokenFromStorage();

  if (!isAuthenticated) {
    next({ name: "LoginPage" });
  } else {
    if (!userStore.isLogin) {
      try {
        await staticStore.getStatic();
        next();
      } catch (e) {
        // logout
        next({ name: "LoginPage" });
      }
    }
    next();
  }
}

import { ref } from "vue";

export default ref([
  {
    label: "Ритейл",
    value: false,
  },
  {
    label: "Опт",
    value: false,
  },
  {
    label: "Админка",
    value: false,
  },
]);

import { UnwrapNestedRefs } from "@vue/reactivity";
import { FormRules } from "element-plus";
import { reactive } from "vue";
import { SkinConditionsForm } from "@/components/static/skinConditions-form/skinConditions-form.types";

export const _skinConditionFormRules = (): UnwrapNestedRefs<
  FormRules<SkinConditionsForm>
> => {
  return reactive<FormRules<SkinConditionsForm>>({
    title: [{ required: true }],
    slug: [{ required: true }],
  });
};

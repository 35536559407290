import { reactive } from "vue";
import { FormRules } from "element-plus";
import {
  UserCommonForm,
  UserForm,
  UserPasswordForm,
} from "@/components/user-form/user-form.types";
import password from "@/validations/password";
import { UnwrapNestedRefs } from "@vue/reactivity";

export const _passwordFormRules = (
  form: UnwrapNestedRefs<UserPasswordForm>
): UnwrapNestedRefs<FormRules<UserPasswordForm>> => {
  return reactive<FormRules<UserPasswordForm>>({
    oldPassword: [{ required: true, min: 6 }],
    password: [{ required: true, trigger: "blur", min: 6 }],
    passwordRepeat: [
      { validator: password(form), required: true, trigger: "blur" },
    ],
  });
};

export const _commonFormRules = (): UnwrapNestedRefs<
  FormRules<UserCommonForm>
> => {
  return reactive<FormRules<UserCommonForm>>({
    contact_type: [{ required: true, trigger: "change" }],
    first_name: [{ required: true, trigger: "blur" }],
    last_name: [{ required: true, trigger: "blur" }],
    patronymic: [{ required: true, trigger: "blur" }],
    phone: [{ required: true, trigger: "blur" }],
    email: [{ required: true, trigger: "blur" }],
    passport: [{ required: true, trigger: "blur" }],
    organization_name: [{ required: true, trigger: "blur" }],
    inn: [{ required: true, trigger: "blur" }],
    contact_person: [{ required: true, trigger: "blur" }],
    shop_url: [{ required: true, trigger: "blur" }],
  });
};

export const _userFormRules = (): UnwrapNestedRefs<FormRules<UserForm>> => {
  return reactive<FormRules<UserForm>>({
    login: [{ required: true, trigger: "blur" }],
    displayName: [{ required: true, trigger: "blur" }],
    email: [{ required: true, trigger: "blur" }],
    role: [{ required: true, trigger: "blur" }],
  });
};

import { UnwrapNestedRefs } from "@vue/reactivity";
import { FormRules } from "element-plus";
import { ProductForm } from "@/components/product-form/product-form.types";
import { reactive } from "vue";

export const _productFormRules = (): UnwrapNestedRefs<
  FormRules<ProductForm>
> => {
  return reactive<FormRules<ProductForm>>({
    title: [{ required: true }],
    price: [{ required: true, min: 0, type: "number" }],
  });
};

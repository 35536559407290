import { reactive } from "vue";
import { FormRules } from "element-plus";
import password from "@/validations/password";
import { UnwrapNestedRefs } from "@vue/reactivity";
import { NewUserForm } from "@/components/new-user-form/new-user-form.types";
import userRole from "@/validations/userRole";

export const _newUserFormRules = (
  form: UnwrapNestedRefs<NewUserForm>
): UnwrapNestedRefs<FormRules<NewUserForm>> => {
  return reactive<FormRules<NewUserForm>>({
    login: [{ required: true, trigger: "blur" }],
    display_name: [{ required: true, trigger: "blur" }],
    email: [
      {
        required: true,
        trigger: "blur",
        type: "email",
      },
    ],
    password: [{ required: true, trigger: "blur", min: 6 }],
    password_repeat: [
      { validator: password(form), required: true, trigger: "blur" },
    ],
    role: [{ validator: userRole, type: "number", trigger: "blur" }],
  });
};

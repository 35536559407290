import { ProductForm } from "@/components/product-form/product-form.types";

export const _productForm: ProductForm = {
  title: "",
  slug: "",
  description: "",
  to_whom: "",
  what_do: "",
  composition: "",
  how_to_use: "",
  safety: "",
  upsales: [],
  crossales: [],
  price: 0,
  price_sale: 0,
  wholesale_price: [],
  stock: 1,
  brand: [],
  ingredient: [],
  effect: [],
  skin_condition: [],
  skin_type: [],
  catalog: [],
  hidden: false,
  images: [],
};

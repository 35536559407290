import { defineStore } from "pinia";
import { ApiMethods, ApiResponses } from "patchandgo-ui";
import utils from "@/utils";
import request from "@/store/request";
import { BrandForm } from "@/components/static/brand-form/brand-form.types";
import { EffectsForm } from "@/components/static/effects-form/effects-form.types";
import { IngredientsForm } from "@/components/static/ingredients-form/ingredients-form.types";
import { CatalogsForm } from "@/components/static/catalogs-form/catalogs-form.types";
import { SkinTypesForm } from "@/components/static/skinTypes-form/skinTypes-form.types";
import { SkinConditionsForm } from "@/components/static/skinConditions-form/skinConditions-form.types";
import { ShippingTksForm } from "@/components/static/shippingTks-form/shippingTks-form.types";

export interface StaticStoreState {
  static: ApiResponses.StaticResponse | null;
}

export const useStaticStore = defineStore("staticStore", {
  state: (): StaticStoreState => {
    return {
      static: null,
    };
  },
  actions: {
    async getStatic() {
      try {
        this.static = await request.send<ApiResponses.StaticResponse>(
          "get",
          ApiMethods.GET_STATIC
        );
      } catch (err) {
        utils.handleHttpError(err as number);
      }
    },

    async addBrand(form: BrandForm) {
      try {
        await request.send("post", "/static/brand/", form);
      } catch (err) {
        utils.handleHttpError(err as number);
      }
    },
    async updateBrandBySlug(slug: string, form: BrandForm) {
      try {
        await request.send(
          "put",
          `/static/brand/${slug}`,
          (({ slug, ...o }) => o)(form)
        );
      } catch (err) {
        utils.handleHttpError(err as number);
      }
    },
    async deleteBrandBySlug(slug: string) {
      try {
        await request.send("delete", `/static/brand/${slug}`);
      } catch (err) {
        utils.handleHttpError(err as number);
      }
    },

    async addEffect(form: EffectsForm) {
      try {
        await request.send("post", "/static/effect/", form);
      } catch (err) {
        utils.handleHttpError(err as number);
      }
    },
    async updateEffectBySlug(slug: string, form: EffectsForm) {
      try {
        await request.send(
          "put",
          `/static/effect/${slug}`,
          (({ slug, ...o }) => o)(form)
        );
      } catch (err) {
        utils.handleHttpError(err as number);
      }
    },
    async deleteEffectBySlug(slug: string) {
      try {
        await request.send("delete", `/static/effect/${slug}`);
      } catch (err) {
        utils.handleHttpError(err as number);
      }
    },

    async addIngredient(form: IngredientsForm) {
      try {
        await request.send("post", "/static/ingredient/", form);
      } catch (err) {
        utils.handleHttpError(err as number);
      }
    },
    async updateIngredientBySlug(slug: string, form: IngredientsForm) {
      try {
        await request.send(
          "put",
          `/static/ingredient/${slug}`,
          (({ slug, ...o }) => o)(form)
        );
      } catch (err) {
        utils.handleHttpError(err as number);
      }
    },
    async deleteIngredientBySlug(slug: string) {
      try {
        await request.send("delete", `/static/ingredient/${slug}`);
      } catch (err) {
        utils.handleHttpError(err as number);
      }
    },

    async addCatalog(form: CatalogsForm) {
      try {
        await request.send("post", "/static/catalog/", form);
      } catch (err) {
        utils.handleHttpError(err as number);
      }
    },
    async updateCatalogBySlug(slug: string, form: CatalogsForm) {
      try {
        await request.send(
          "put",
          `/static/catalog/${slug}`,
          (({ slug, ...o }) => o)(form)
        );
      } catch (err) {
        utils.handleHttpError(err as number);
      }
    },
    async deleteCatalogBySlug(slug: string) {
      try {
        await request.send("delete", `/static/catalog/${slug}`);
      } catch (err) {
        utils.handleHttpError(err as number);
      }
    },

    async addSkinType(form: SkinTypesForm) {
      try {
        await request.send("post", "/static/skin_type/", form);
      } catch (err) {
        utils.handleHttpError(err as number);
      }
    },
    async updateSkinTypeBySlug(slug: string, form: SkinTypesForm) {
      try {
        await request.send(
          "put",
          `/static/skin_type/${slug}`,
          (({ slug, ...o }) => o)(form)
        );
      } catch (err) {
        utils.handleHttpError(err as number);
      }
    },
    async deleteSkinTypeBySlug(slug: string) {
      try {
        await request.send("delete", `/static/skin_type/${slug}`);
      } catch (err) {
        utils.handleHttpError(err as number);
      }
    },

    async addSkinCondition(form: SkinConditionsForm) {
      try {
        await request.send("post", "/static/skin_condition/", form);
      } catch (err) {
        utils.handleHttpError(err as number);
      }
    },
    async updateSkinConditionBySlug(slug: string, form: SkinConditionsForm) {
      try {
        await request.send(
          "put",
          `/static/skin_condition/${slug}`,
          (({ slug, ...o }) => o)(form)
        );
      } catch (err) {
        utils.handleHttpError(err as number);
      }
    },
    async deleteSkinConditionBySlug(slug: string) {
      try {
        await request.send("delete", `/static/skin_condition/${slug}`);
      } catch (err) {
        utils.handleHttpError(err as number);
      }
    },

    async addShippingTk(form: ShippingTksForm) {
      try {
        await request.send("post", "/static/shipping_tk/", form);
      } catch (err) {
        utils.handleHttpError(err as number);
      }
    },
    async updateShippingTkBySlug(slug: string, form: ShippingTksForm) {
      try {
        await request.send(
          "put",
          `/static/shipping_tk/${slug}`,
          (({ slug, ...o }) => o)(form)
        );
      } catch (err) {
        utils.handleHttpError(err as number);
      }
    },
    async deleteShippingTkBySlug(slug: string) {
      try {
        await request.send("delete", `/static/shipping_tk/${slug}`);
      } catch (err) {
        utils.handleHttpError(err as number);
      }
    },
  },
});

<script lang="ts" setup></script>
<template>
  <router-view />
</template>
<style lang="scss">
.el-table__header {
  width: 100% !important;
  flex: 1;
}

.el-table__body {
  width: 100% !important;
  flex: 1;
}
.el-header {
  border-bottom: solid 1px var(--el-menu-border-color);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  z-index: 999;
}
.el-aside {
  height: calc(100vh - 60px);
  position: fixed;
  top: 60px;
  left: 0;
  background: #fff;
  z-index: 999;
  & > .el-menu {
    height: 100%;
  }
}

.el-main {
  padding: 80px 8px 20px 258px !important;
  .container-fluid {
    .row {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>

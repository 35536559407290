import {
  AsideMenuItem,
  AsideMenuRawItem,
} from "@/components/aside/aside.types";
import { ref } from "vue";
import { Ref, UnwrapRef } from "@vue/reactivity";
import { Types } from "patchandgo-ui";
import config from "@/config";
import { CatalogsTreeItem } from "@/utils/types";

export function makeAsideMenu(
  menu: AsideMenuRawItem[]
): Ref<UnwrapRef<AsideMenuItem[]>> {
  const recursiveHandle = (
    items: AsideMenuRawItem[],
    id: string
  ): AsideMenuItem[] => {
    return items.map((item, index) => {
      const { title, icon, children, page } = item;
      const asideMenuItem: AsideMenuItem = {
        title,
        icon,
        page,
        id: `${id}${index}`,
      };

      return children
        ? { ...asideMenuItem, children: recursiveHandle(children, `${index}-`) }
        : asideMenuItem;
    });
  };

  return ref<AsideMenuItem[]>(recursiveHandle(menu, ""));
}

export function findActiveRouteIdByName(menu: AsideMenuItem[], name: string) {
  let s: string | null = null;
  (function recursiveSearch(items: AsideMenuItem[]) {
    for (let i = 0; i < items.length; i++) {
      if (items[i]?.page === name) {
        s = items[i].id;
      }

      if (items[i]?.children)
        recursiveSearch(items[i].children as AsideMenuItem[]);
    }
  })(menu);

  return s;
}

export function toBinaryNumber(num: string): number {
  return parseInt(num, 2);
}

export function toDecimalNumber(num: number, length?: number) {
  let result = num.toString(2);
  if (length) {
    let newResult = result;
    for (let i = 0; i < length - result.length; i++) {
      newResult = "0" + newResult;
    }

    result = newResult;
  }
  return result;
}

export function booleanToInt(val: boolean): number {
  return val ? 1 : 0;
}

export function stringToBoolean(val: string): boolean {
  return val === "1";
}

export function getRealProductLink(slug: string) {
  return (config.PRODUCT_RETAIL_URL || "").replace(":slug", slug);
}

export function parseValueToArray<T>(value: any): T[] {
  if (!value || !value?.length) return [];

  return value as T[];
}

export function searchByStatic<T extends { title: string; slug: string }>(
  list: T[],
  searchString: string
): T[] {
  return (
    list.filter((item) => {
      searchString = searchString.toLowerCase();
      const itemTitleString = item.title.toLowerCase();
      const itemSlugString = item.slug.toLowerCase();

      return (
        itemTitleString.search(searchString) !== -1 ||
        itemSlugString.search(searchString) !== -1
      );
    }) || []
  );
}

export function makeStaticRecursiveTree<R extends object>(list: any[]): R[] {
  const data: R[] = [];

  const fillChildren = (slug: string): R[] =>
    list
      .filter((childrenItem) => childrenItem.parent === slug)
      .map((childItem) => ({
        ...childItem,
        children: fillChildren(childItem.slug),
      }));
  list
    .filter((listItem) => !listItem.parent)
    .forEach((listItem) => {
      data.push({
        ...listItem,
        children: fillChildren(listItem.slug),
      });
    });

  return data;
}

import { UnwrapNestedRefs } from "@vue/reactivity";
import { FormRules } from "element-plus";
import { reactive } from "vue";
import { IngredientsForm } from "@/components/static/ingredients-form/ingredients-form.types";

export const _ingredientsFormRules = (): UnwrapNestedRefs<
  FormRules<IngredientsForm>
> => {
  return reactive<FormRules<IngredientsForm>>({});
};

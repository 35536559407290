import { NewUserForm } from "@/components/new-user-form/new-user-form.types";

export const _newUserForm: NewUserForm = {
  login: "",
  display_name: "",
  email: "",
  password: "",
  password_repeat: "",
  role: 4,
};

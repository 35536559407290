import { defineStore } from "pinia";
import { Types, ApiResponses } from "patchandgo-ui";
import request from "@/store/request";

interface OrdersStoreState {
  orders: Types.OrderWholesale[];
  order: ApiResponses.OrderWholesaleResponse;
  total: number;
}

export const useOrdersStore = defineStore("ordersStore", {
  state(): OrdersStoreState {
    return {
      orders: [],
      order: {} as ApiResponses.OrderWholesaleResponse,
      total: 0,
    };
  },
  actions: {
    async getWholesaleOrders(params: Types.GetOrdersParams = {}) {
      try {
        const { orders, total } =
          await request.send<ApiResponses.OrderWholesaleListResponse>(
            "get",
            "/order/wholesale/",
            params
          );
        this.orders = orders;
        this.total = total;
      } catch (err) {
        console.log(err);
      }
    },
    async getWholesaleOrderById(id: number) {
      this.order = await request.send<ApiResponses.OrderWholesaleResponse>(
        "get",
        `/order/wholesale/${id}`
      );
    },
    async updateOrder(form: object, id: string | number) {
      this.order = await request.send<ApiResponses.OrderWholesaleResponse>(
        "put",
        `/order/wholesale/${id}`,
        form
      );

      return this.order;
    },
  },
});

interface Config {
  API_URL: string;
  PRODUCT_RETAIL_URL: string;
  WHOLESALE_TIERS: string;
}

const cfg: Config = (window as any).cfg;
const configObj = {
  API_URL: cfg.API_URL,
  PRODUCT_RETAIL_URL: cfg.PRODUCT_RETAIL_URL,
  WHOLESALE_TIERS: cfg.WHOLESALE_TIERS,
};

export default configObj;

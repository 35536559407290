import {
  UserCommonForm,
  UserForm,
  UserPasswordForm,
} from "@/components/user-form/user-form.types";

export const _passwordForm: UserPasswordForm = {
  oldPassword: "",
  password: "",
  passwordRepeat: "",
};

export const _commonForm: UserCommonForm = {
  contact_type: "personal",
  first_name: "",
  last_name: "",
  patronymic: "",
  passport: "",
  phone: "",
  contact_person: "",
  organization_name: "",
  shop_url: "",
  inn: "",
  email: "",
};

export const _userForm: UserForm = {
  login: "",
  displayName: "",
  email: "",
  role: 0,
};

import {
  OrderWholesaleDeliveryForm,
  OrderWholesaleMainInfoForm,
} from "@/components/orders-forms/order-wholesale-form/order-wholesale.types";

export const _orderWholesaleMainInfoForm: OrderWholesaleMainInfoForm = {
  status: "",
};

export const _orderWholesaleMainDeliveryForm: OrderWholesaleDeliveryForm = {
  address: "",
  insurance: false,
  warm: false,
  crate: false,
  comment: "",
  tk: "",
  destination: "door",
};

import { UnwrapNestedRefs } from "@vue/reactivity";
import { FormRules } from "element-plus";
import { reactive } from "vue";
import { BrandForm } from "@/components/static/brand-form/brand-form.types";

export const _brandFormRules = (): UnwrapNestedRefs<FormRules<BrandForm>> => {
  return reactive<FormRules<BrandForm>>({
    title: [{ required: true }],
    slug: [{ required: true }],
  });
};

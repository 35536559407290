export default function (ruleForm: any) {
  return (rule: any, value: number, callback: any) => {
    if (!value) {
      callback(new Error("Введите пароль еще раз"));
    } else if (value !== ruleForm.password) {
      callback(new Error("Пароли должны совпадать"));
    } else {
      callback();
    }
  };
}

import { createApp, markRaw } from "vue";
import { createPinia } from "pinia";
import ElementPlus from "element-plus";
import { PagFilters } from "patchandgo-ui";
import ru from "element-plus/dist/locale/ru.mjs";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import App from "./App.vue";
import router from "./router";
import _resizeElementErrorFix from "./_resizeElementErrorFix";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

import "element-plus/dist/index.css";
import "bootstrap/dist/css/bootstrap-grid.css";
import "bootstrap/dist/css/bootstrap-reboot.css";
import "./assets/style/element-variables.scss";
import "./assets/style/vue-quill.scss";
import "./assets/style/_element-ui.scss";

const pinia = createPinia();
const app = createApp(App);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

pinia.use(({ store }) => {
  store.$router = markRaw(router);
});

app.use(PagFilters.default);
app.use(pinia);
app.use(router);
app.use(ElementPlus, {
  locale: ru,
});
app.component("QuillEditor", QuillEditor);
app.mount("#app");
_resizeElementErrorFix();

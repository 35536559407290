import axios from "axios";
import utils from "@/utils";
import { useUserStore } from "@/store/user.store";
import { MessageService, PagUtilsCommon, Types } from "patchandgo-ui";
import config from "@/config";

const TIME_BEFORE_THE_TOKEN_EXPIRED = 1000 * 60 * 1.5; // 5 minutes
const MAX_ATTEMPTS = 20;

class Request {
  private static instance: Request;
  private requestAccess: boolean;
  private axiosInstance: any;
  private attempts = 0;

  constructor() {
    this.requestAccess = true;

    this.axiosInstance = axios.create({
      baseURL: config.API_URL,
    });
  }

  public static getInstance(): Request {
    if (!Request.instance) {
      Request.instance = new Request();
    }

    return Request.instance;
  }

  private isTimeExpired(): boolean {
    return (
      new Date(utils.getUserExpiryTokenFromStorage() as string).getTime() -
        new Date().getTime() <
      TIME_BEFORE_THE_TOKEN_EXPIRED
    );
  }

  private checkRequestAccess(ignore = false): Promise<void> {
    return new Promise((resolve, reject) => {
      let to: any = null;
      const recursiveCheck = () => {
        if (ignore) {
          resolve();

          return;
        }

        const timeout = this.attempts <= 0 ? 0 : 500;
        this.attempts += 1;
        to = setTimeout(() => {
          clearTimeout(to);
          if (this.requestAccess) {
            this.attempts = 0;
            resolve();
          } else {
            if (this.attempts >= MAX_ATTEMPTS) {
              this.attempts = 0;
              reject();
            } else {
              recursiveCheck();
            }
          }
        }, timeout);
      };

      recursiveCheck();
    });
  }

  private getRequestHeaders() {
    return {
      headers: {
        Authorization: `Bearer ${utils.getUserTokenFromStorage()}`,
      },
    };
  }

  public send<T>(
    method: "get" | "post" | "put" | "delete",
    url: string,
    data: object | null = null,
    authHeader = true,
    ignoreAccess = false
  ): Promise<T> {
    let requestConfig: any = {
      method,
      url,
    };

    if (method === "get")
      requestConfig = {
        ...requestConfig,
        params: data,
        paramsSerializer: { indexes: null },
      };
    else requestConfig = { ...requestConfig, data };

    const updateRequestLocalConfig = () => {
      if (authHeader) {
        requestConfig = {
          ...requestConfig,
          ...this.getRequestHeaders(),
        };
      }
    };

    updateRequestLocalConfig();

    return new Promise((resolve, reject) => {
      const userStore = useUserStore();
      this.checkRequestAccess(ignoreAccess)
        .then(async () => {
          const req = this.axiosInstance;

          try {
            if (this.isTimeExpired() && !RegExp("\\bauth\\b").test(url)) {
              await userStore.refreshToken();
              updateRequestLocalConfig();
            }
            const { data } = await req.request(requestConfig);
            resolve(data);
          } catch (err) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const code = err.response.data.error_code;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const httpStatus = err.request?.status;

            if (code === Types.API_ERROR_CODE.ErrExpiredAccessToken) {
              this.requestAccess = false;
              try {
                await userStore.refreshToken();
                this.requestAccess = true;

                updateRequestLocalConfig();
                const data = await req.request(requestConfig);
                resolve(data);
              } catch (e) {
                this.requestAccess = true;
                await userStore.logout();
                reject();
              }
            } else if (PagUtilsCommon.isAuthError(code)) {
              await userStore.logout();
            } else if (httpStatus === 401) {
              await userStore.logout();
              reject(code);
            } else {
              reject(code);
            }

            MessageService.showErrorMessage(
              PagUtilsCommon.getHttpErrorMessage(code)
            );
          }
        })
        .catch((err) => {
          reject(new Error(err));
        });
    });
  }
}

export default Request.getInstance();

import { makeAsideMenu } from "@/utils/utils";

export default makeAsideMenu([
  {
    title: "Обзор",
    icon: "PieChart",
    page: "Home",
  },
  {
    title: "Пользователи",
    icon: "User",
    children: [
      { title: "Все пользователи", page: "AllUsers" },
      { title: "Добавить нового", page: "UserNewForm" },
    ],
  },
  {
    title: "Заказы",
    icon: "Box",
    children: [
      // { title: "Розничные заказы", page: "OrdersPageRetail" },
      { title: "Оптовые заказы", page: "OrdersPageWholesale" },
      { title: "Открыть по номеру", page: "OrderPageOpenByNumber" },
    ],
  },
  {
    title: "Товары",
    icon: "Goods",
    children: [{ title: "Все товары", page: "AllProducts" }],
  },
  {
    title: "Данные",
    icon: "Collection",
    children: [
      { title: "Бренды", page: "StaticBrands" },
      { title: "Каталоги", page: "StaticCatalogs" },
      { title: "Действия", page: "StaticEffects" },
      { title: "Ингредиенты", page: "StaticIngredients" },
      { title: "Состояние кожи", page: "StaticSkinConditions" },
      { title: "Типы кожи", page: "StaticSkinTypes" },
      { title: "Транспортные компании", page: "StaticShippingTks" },
    ],
  },
]);

import { ApiResponses } from "patchandgo-ui";
import cookie from "./cookie";

const STORAGE_USER_EXPIRY_NAME = "expiry";
const STORAGE_USER_REMEMBER_NAME = "r";

const utils = {
  setAuthDataInStorage(
    data: ApiResponses.LoginPostResponse,
    remember: boolean
  ) {
    cookie.setAuthToken(data.access_token, remember);
    cookie.setRefreshToken(data.refresh_token);
    localStorage.setItem(STORAGE_USER_EXPIRY_NAME, data.expiry);
    localStorage.setItem(
      STORAGE_USER_REMEMBER_NAME,
      utils.booleanToString(remember)
    );
  },
  getUserTokenFromStorage() {
    return cookie.getAuthToken();
  },
  getUserRefreshTokenFromStorage() {
    return cookie.getRefreshToken();
  },
  getUserExpiryTokenFromStorage() {
    return localStorage.getItem(STORAGE_USER_EXPIRY_NAME);
  },
  getUserRememberValueFromStorage() {
    return localStorage.getItem(STORAGE_USER_REMEMBER_NAME);
  },
  removeAllTokensInStorage() {
    cookie.removeAll();
    localStorage.removeItem(STORAGE_USER_EXPIRY_NAME);
    localStorage.removeItem(STORAGE_USER_REMEMBER_NAME);
  },
  handleHttpError(errCode: number) {
    throw errCode;
  },
  booleanToString(val: boolean): string {
    return val ? "1" : "0";
  },
  stringToBoolean(val: string): boolean {
    return val === "1";
  },
};

export default utils;
